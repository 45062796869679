import React, { useState } from 'react';

const EditStudentModal = ({ student, onSave, onCancel }) => {
  const [updatedStudent, setUpdatedStudent] = useState({ ...student });
  const [photo, setPhoto] = useState(null);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedStudent((prevStudent) => ({
      ...prevStudent,
      [name]: value,
    }));
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        setError('File size should not exceed 5MB');
      } else {
        setError(null);
        setPhoto(file);
      }
    }
  };

  const handleSave = async () => {
    setError(null);
    const formData = new FormData();
    Object.keys(updatedStudent).forEach(key => {
      formData.append(key, updatedStudent[key]);
    });
    if (photo) {
      formData.append('photo', photo);
    }

    try {
      const response = await fetch('https://rosemary.bluebugsoft.com/rosemary_db/update_student.php', {
        method: 'POST',
        body: formData,
      });

      const responseText = await response.text();
      console.log('Raw response:', responseText);

      let data;
      try {
        data = JSON.parse(responseText);
      } catch (parseError) {
        console.error('JSON Parse Error:', parseError);
        setError(`Failed to parse server response: ${responseText}`);
        return;
      }

      if (data.success) {
        onSave(updatedStudent);
      } else {
        setError(data.error || 'An error occurred while updating the student.');
      }
    } catch (error) {
      console.error('Fetch Error:', error);
      setError(`An unexpected error occurred: ${error.message}`);
    }
  };

  return (
    <div className='popup-editstudent' style={modalStyles.overlay}>
      <div style={modalStyles.modalContent}>
        <h2>Edit Student Information</h2>
        {error && <div style={modalStyles.error}>{error}</div>}
        <div className='form-row'>
          <div className="form-group">
            <label>First Name:</label>
            <input type="text" className='form-control' name="firstName" value={updatedStudent.firstName} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>Middle Name:</label>
            <input className='form-control' type="text" name="middleName" value={updatedStudent.middleName} onChange={handleChange} />
          </div>
        </div>
        <div className='form-row'>
          <div className="form-group">
            <label>Last Name:</label>
            <input className='form-control' type="text" name="lastName" value={updatedStudent.lastName} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>Class:</label>
            <input className='form-control' type="text" name="className" value={updatedStudent.className} onChange={handleChange} />
          </div>
        </div>
        <div className='form-row'>
          <div className="form-group">
            <label>Gender:</label>
            <input className='form-control' type="text" name="gender" value={updatedStudent.gender} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>Date of Birth:</label>
            <input className='form-control' type="date" name="dateOfBirth" value={updatedStudent.dateOfBirth} onChange={handleChange} />
          </div>
        </div>
        <div className='form-row'>
          <div className="form-group">
            <label>Father's Name:</label>
            <input className='form-control' type="text" name="fathersName" value={updatedStudent.fathersName} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>Mother's Name:</label>
            <input className='form-control' type="text" name="mothersName" value={updatedStudent.mothersName} onChange={handleChange} />
          </div>
        </div>
        <div className='form-row'>
          <div className="form-group">
            <label>Phone Number:</label>
            <input className='form-control' type="text" name="phoneNumber" value={updatedStudent.phoneNumber} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input className='form-control' type="email" name="email" value={updatedStudent.email} onChange={handleChange} />
          </div>
        </div>
        <div className='form-row'>
          <div className="form-group">
            <label>Address:</label>
            <input className='form-control' type="text" name="address" value={updatedStudent.address} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>Alternative Phone Number:</label>
            <input className='form-control' type="text" name="alternativePhoneNumber" value={updatedStudent.alternativePhoneNumber} onChange={handleChange} />
          </div>
        </div>
        <div className='form-row'>
          <div className="form-group">
            <label>Photo:</label>
            <input 
              className='form-control' 
              type="file" 
              accept="image/*"
              onChange={handlePhotoChange} 
            />
            {photo && <p>Selected file: {photo.name}</p>}
          </div>
        </div>
        <div style={modalStyles.modalActions}>
          <button className='badge bg-success' onClick={handleSave}>Save</button>
          <button className='badge bg-warning' onClick={onCancel}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '10px',
    width: '500px',
    maxWidth: '90%',
    maxHeight: '90%',
    overflowY: 'auto',
  },
  modalActions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  error: {
    color: 'red',
    marginBottom: '10px',
  },
};

export default EditStudentModal;
import React, { useState, useEffect } from 'react';

const EnrollmentForm = ({ onStudentDataChange }) => {
  const [studentData, setStudentData] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    photo: null,
    dateOfBirth: '',
    gender: '',
    className: '',
    studentId: '',
    fathersName: '',
    mothersName: '',
    address: '',
    phoneNumber: '',
    alternativePhoneNumber: '',
    email: ''
  });
  const [classes, setClasses] = useState([]);
  const [errors, setErrors] = useState({});
  const generateNewStudentId = () => {
    const currentYear = new Date().getFullYear();
    const randomNumber = Math.floor(10000 + Math.random() * 90000); // 5-digit random number
    return `${currentYear}${randomNumber}`;
  };
  
  const resetForm = () => {
    setStudentData({
      firstName: '',
      middleName: '',
      lastName: '',
      photo: null,
      dateOfBirth: '',
      gender: '',
      className: '',
      studentId: '',
      fathersName: '',
      mothersName: '',
      address: '',
      phoneNumber: '',
      alternativePhoneNumber: '',
      email: ''
    });
    setErrors({});
  };

  useEffect(() => {
    const newStudentId = generateNewStudentId();
    setStudentData((prevState) => ({
      ...prevState,
      studentId: newStudentId
    }));
    onStudentDataChange((prevState) => ({
      ...prevState,
      studentId: newStudentId
    }));
  
    fetch('https://rosemary.bluebugsoft.com/rosemary_db/get_classes.php')
      .then(response => response.json())
      .then(data => setClasses(data))
      .catch(error => console.error('Error fetching classes:', error));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedStudentData = { ...studentData, [name]: value };
    setStudentData(updatedStudentData);
    onStudentDataChange(updatedStudentData);

    // Validate input fields
    validateFields(name, value);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const updatedStudentData = { ...studentData, photo: file };
    setStudentData(updatedStudentData);
    onStudentDataChange(updatedStudentData);
  };

  const validateFields = (name, value) => {
    let errors = {};

    // Phone number validation (assuming a standard 10-digit format)
    if (name === 'phoneNumber' || name === 'alternativePhoneNumber') {
      const phonePattern = /^[0-9]{10}$/;
      if (!phonePattern.test(value)) {
        errors[name] = 'Phone number must be 10 digits';
      } else {
        errors[name] = '';
      }
    }

    // Email validation
    if (name === 'email') {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(value)) {
        errors.email = 'Invalid email address';
      } else {
        errors.email = '';
      }
    }

    setErrors(errors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check for errors before submission
    if (Object.values(errors).some(error => error)) {
      alert('Please fix the errors in the form before submitting.');
      return;
    }

    const formData = new FormData();
    formData.append('firstName', studentData.firstName);
    formData.append('middleName', studentData.middleName);
    formData.append('lastName', studentData.lastName);
    formData.append('dateOfBirth', studentData.dateOfBirth);
    formData.append('gender', studentData.gender);
    formData.append('className', studentData.className);
    formData.append('studentId', studentData.studentId);
    formData.append('fathersName', studentData.fathersName);
    formData.append('mothersName', studentData.mothersName);
    formData.append('address', studentData.address);
    formData.append('phoneNumber', studentData.phoneNumber);
    formData.append('alternativePhoneNumber', studentData.alternativePhoneNumber);
    formData.append('email', studentData.email);
    if (studentData.photo) {
      formData.append('photo', studentData.photo);
    }

    try {
      const response = await fetch('https://rosemary.bluebugsoft.com/rosemary_db/addStudent.php', {
        method: 'POST',
        body: formData
      });
  
      const result = await response.text();
      console.log(result);
  
      if (response.ok) {
        alert('Student data successfully uploaded!');
        resetForm(); // Reset the form
        // Generate a new student ID for the next submission
        const newStudentId = generateNewStudentId();
        setStudentData(prevState => ({ ...prevState, studentId: newStudentId }));
        onStudentDataChange(prevState => ({ ...prevState, studentId: newStudentId }));
      } else {
        alert('Failed to upload data. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <div className='enrollment-form-section box'>
      <h2 className='main-heading'>Enrollment Form</h2>
      <form className="enrollment-form" onSubmit={handleSubmit} encType="multipart/form-data">
        <h5 className='sub-heading'>Student Details</h5>
        <div className='form-row'>
          <div className="form-group">
            <label>First Name</label>
            <input type="text" className='form-control' name="firstName" value={studentData.firstName} onChange={handleChange} required/>
          </div>
          <div className="form-group">
            <label>Middle Name</label>
            <input type="text" name="middleName" className='form-control' value={studentData.middleName} onChange={handleChange} />
          </div>
        </div>
        <div className='form-row'>
          <div className="form-group">
            <label>Last Name</label>
            <input type="text" className='form-control' name="lastName" value={studentData.lastName} onChange={handleChange} required/>
          </div>
          <div className="form-group">
            <label>Upload Photo</label>
            <input type="file" className='form-control' name="photo" onChange={handleFileChange} required key={studentData.studentId}/>
          </div>
        </div>
        <div className='form-row'>
          <div className="form-group">
            <label>Date of Birth</label>
            <input type="date" className='form-control' name="dateOfBirth" value={studentData.dateOfBirth} onChange={handleChange} required/>
          </div>
          <div className="form-group">
            <label>Gender</label>
            <select
              className='form-control'
              name="gender"
              value={studentData.gender}
              onChange={handleChange}
              required
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="form-group">
            <label>Enrolled To Class</label>
            <select
              className='form-control'
              name="className"
              value={studentData.className}
              onChange={handleChange} required
            >
              <option value="">Select a class</option>
              {classes.map((classItem) => (
                <option key={classItem.id} value={classItem.class_name}>
                  {classItem.class_name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <input type="hidden" name="studentId" value={studentData.studentId} />
        <h5 className='sub-heading'>Guardian Details</h5>
        <div className='form-row'>
          <div className="form-group">
            <label>Father's Name</label>
            <input type="text" className='form-control' name="fathersName" value={studentData.fathersName} onChange={handleChange} required/>
          </div>
          <div className="form-group">
            <label>Mother's Name</label>
            <input type="text" className='form-control' name="mothersName" value={studentData.mothersName} onChange={handleChange} required/>
          </div>
        </div>
        <div className='form-row'>
          <div className="form-group">
            <label>Address</label>
            <input type="text" className='form-control' name="address" value={studentData.address} onChange={handleChange} required/>
          </div>
          <div className="form-group">
            <label>Phone Number</label>
            <input type="number" className='form-control' name="phoneNumber" value={studentData.phoneNumber} onChange={handleChange} required />
            {errors.phoneNumber && <p className="error-text">{errors.phoneNumber}</p>}
          </div>
        </div>
        <div className='form-row'>
          <div className="form-group">
            <label>Alternative Phone Number</label>
            <input type="number" className='form-control' name="alternativePhoneNumber" value={studentData.alternativePhoneNumber} onChange={handleChange} />
            {errors.alternativePhoneNumber && <p className="error-text">{errors.alternativePhoneNumber}</p>}
          </div>
          <div className="form-group">
            <label>Email</label>
            <input type="email" className='form-control' name="email" value={studentData.email} onChange={handleChange} required />
            {errors.email && <p className="error-text">{errors.email}</p>}
          </div>
        </div>
        <button type="submit" className='badge bg-success'>Submit</button>
      </form>
    </div>
  );
};

export default EnrollmentForm;

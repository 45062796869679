import React, { useState, useEffect } from 'react';
import StudentList from '../../components/StudentList';
import StudentInfo from '../../components/StudentInfo';
import EditStudentModal from '../../components/EditStudentModal';

const Students = () => {
  const [students, setStudents] = useState([]);
  const [classes, setClasses] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    // Fetch classes
    fetch('https://rosemary.bluebugsoft.com/rosemary_db/get_classes.php')
      .then(response => response.json())
      .then(data => setClasses(data))
      .catch(error => console.error('Error fetching classes:', error));
  }, []);

  const handleSelectStudent = (student) => {
    setSelectedStudent(student);
  };

 const handleDeleteStudent = (studentId) => {
  if (window.confirm('Are you sure you want to delete this student?')) {
    // Data to be sent
    const data = { studentId: studentId };  // Change 'id' to 'studentId'
  
    
  
    fetch('https://rosemary.bluebugsoft.com/rosemary_db/delete_student.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setStudents(prevStudents => prevStudents.filter(student => student.studentId !== studentId));
          setSelectedStudent(null);
          alert('Student deleted successfully');
        } else {
          throw new Error(data.error || 'Failed to delete student');
        }
      })
      .catch(error => {
        console.error('Error deleting student:', error);
        alert('Error deleting student. Please try again.');
      });
  }
};
  
  

  const handleEditComplete = (updatedStudent) => {
    setStudents((prevStudents) =>
      prevStudents.map((student) =>
        student.id === updatedStudent.id ? updatedStudent : student
      )
    );
    setIsEditing(false);
    setSelectedStudent(updatedStudent);
    alert('Student information updated successfully');
  };

  return (
    <div className="students-page">
      <StudentList
        students={students}
        onSelectStudent={handleSelectStudent}
        onStudentsFetched={setStudents}
      />
      <StudentInfo
        student={selectedStudent}
        onEdit={() => setIsEditing(true)}
        onDelete={() => handleDeleteStudent(selectedStudent.studentId)}
      />
      {isEditing && (
        <EditStudentModal
          student={selectedStudent}
          onSave={handleEditComplete}
          onCancel={() => setIsEditing(false)}
        />
      )}
    </div>
  );
};

export default Students;
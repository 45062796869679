import React, { useState, useEffect } from 'react';
import UserList from '../../components/UserList';
import AdminUserOverview from '../../components/AdminUserOverview';
import AddAdminUser from '../../components/AddAdminUser';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch('https://rosemary.bluebugsoft.com/rosemary_db/fetch_users.php'); // Your PHP endpoint
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        // Format the users data
        const formattedUsers = data.map(user => ({
          id: user.id,
          name: `${user.first_name} ${user.middle_name} ${user.last_name}`,
          role: user.role
        }));
        setUsers(formattedUsers);
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleView = (user) => {
    setSelectedUser({
      id: user.id,
      firstName: user.name.split(' ')[0],  // Extracting first name
      middleName: user.name.split(' ')[1] || '',  // Extracting middle name
      lastName: user.name.split(' ')[2] || '',  // Extracting last name
      role: user.role
    });
  };

  const handleAdd = (newUser) => {
    setUsers([...users, { ...newUser, id: users.length + 1, name: `${newUser.firstName} ${newUser.middleName} ${newUser.lastName}` }]);
  };

  const handleUpdate = (updatedUser) => {
    setUsers(users.map(user => user.id === updatedUser.id ? updatedUser : user));
  };

  const handleDelete = (userId) => {
    setUsers(users.filter(user => user.id !== userId));
    setSelectedUser(null);
  };

  return (
    <div className="users-page">
      <UserList users={users} onView={handleView} />
      <div className='admin-side'>
        <AddAdminUser onAdd={handleAdd} />
        {selectedUser && <AdminUserOverview selectedUser={selectedUser} onDelete={handleDelete} onUpdate={handleUpdate} />}
      </div>
    </div>
  );
};

export default Users;

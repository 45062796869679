import React, { useState, useEffect } from 'react';

const Dashboard = () => {
  const [classes, setClasses] = useState(0);
  const [students, setStudents] = useState(0);
  const [events, setEvents] = useState([]);
  const [devNotes, setDevNotes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch total classes and students
        const classResponse = await fetch('https://rosemary.bluebugsoft.com/rosemary_db/get_total_classes.php');
        const classData = await classResponse.json();
        setClasses(classData.totalClasses || 0);

        const studentResponse = await fetch('https://rosemary.bluebugsoft.com/rosemary_db/get_total_students.php');
        const studentData = await studentResponse.json();
        setStudents(studentData.totalStudents || 0);

        // Fetch events
        const eventResponse = await fetch('https://rosemary.bluebugsoft.com/rosemary_db/getEvents.php');
        const eventData = await eventResponse.json();
        setEvents(eventData.events || []);

        // Fetch developer notes
        const devNoteResponse = await fetch('https://rosemary.bluebugsoft.com/rosemary_db/get_devnotes.php');
        const devNoteData = await devNoteResponse.json();
        setDevNotes(devNoteData.devNotes || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const handleBackup = () => {
    window.location.href = 'https://rosemary.bluebugsoft.com/rosemary_db/backup.php';
  };
  return (
    <div>
      <div className='dashboard-page-main'>

        <div className='dashboard-page'>
          <div className='first-row'>
            <div className="dashboard-summary box">
              <h2 className='main-heading'>Summary</h2>
              <p className='sub-heading'>Total Classes: {classes}</p>
              <p className='sub-heading'>Total Students: {students}</p>
            </div>
            <div className="dashboard-backup box">
              <p className='main-heading'>Backup your data now</p>
              <button className='badge bg-success' onClick={handleBackup}>Quick Backup</button>
            </div>
          </div>
          <div className="dashboard-events box " style={{ marginTop: '30px' }}>
            <h2 className='main-heading'>Upcoming Events</h2>
            <ul>
              {events.map(event => (
                <li key={event.id}>
                  <strong className='sub-heading'>{event.date}</strong>
                  <div className='sub-heading'>{event.title}</div>
                  <div className='sub-heading'>{event.description}</div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="dashboard-dev-notes box fix-height">
          <h2 className='main-heading'>Developer Notices</h2>
          <ul>
            {devNotes.map(note => (
              <li key={note.id}>
                <div>
                  <span className='sub-heading'><strong>{formatDate(note.created_at)} - {note.title}</strong></span>
                </div>
                <div className='sub-heading'>{note.message}</div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>

  );
};

export default Dashboard;
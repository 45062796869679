import React, { useState, useEffect } from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { format } from 'date-fns';
import logo from '../../pdf/logo.png';

const Fees = () => {
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [students, setStudents] = useState([]);
  const [studentFees, setStudentFees] = useState({});
  const [pdfBlob, setPdfBlob] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(format(new Date(), 'MMMM')); // Default to current month
  const [studentDues, setStudentDues] = useState({});

  useEffect(() => {
    fetch('https://rosemary.bluebugsoft.com/rosemary_db/get_classes.php')
      .then(response => response.json())
      .then(data => setClasses(data))
      .catch(error => console.error('Error fetching classes:', error));
  }, []);

  const fetchStudents = (className) => {
    fetch(`https://rosemary.bluebugsoft.com/rosemary_db/get_students.php?className=${className}`)
      .then(response => response.json())
      .then(data => {
        setStudents(data);
        setStudentFees({});
        data.forEach(student => fetchStudentDues(student.studentId));
      })
      .catch(error => console.error('Error fetching students:', error));
  };

  const fetchStudentDues = (studentId) => {
    fetch(`https://rosemary.bluebugsoft.com/rosemary_db/get_dues.php?studentId=${studentId}`)
      .then(response => response.json())
      .then(data => {
        setStudentDues(prevDues => ({
          ...prevDues,
          [studentId]: data.due_amount || 0
        }));
      })
      .catch(error => console.error('Error fetching student dues:', error));
  };

  const handleSelectClass = (classItem) => {
    setSelectedClass(classItem);
    fetchStudents(classItem.class_name);
  };

  const handleFeeChange = (studentId, feeType, value) => {
    setStudentFees(prevFees => ({
      ...prevFees,
      [studentId]: {
        ...prevFees[studentId],
        [feeType]: value,
      }
    }));
  };

  const handleGenerateBills = async () => {
    const currentDate = new Date();
    const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
    const date = format(currentDate, 'yyyy-MM-dd');
    let receiptNo = 1;

    const generateForm = (student, receiptNo) => `
      <div style="width: calc(33% - 20px); float: left; padding: 20px; margin: 10px; border: 1px dashed black; position: relative;">
        <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: -1; opacity: 0.1;">
          <img src="${logo}" alt="logo" style="width: 50%; height: 50%; margin-left: 25%; margin-top: 25%;" />
        </div>
        <div style="text-align: center;">
          <h2>Rosemary Pre School</h2>
          <p style="margin-bottom:5px;">Email: info@rosemarypreschool.com | Phone: 01-4037009,9843323731</p>
          <p>Address: Kageshwori Manahara, Danchhi, Kathmandu</p>
          <h3>Fee Bill</h3>
        </div>
        <div style="width: 50%; float: left;">
          <p><strong>Student Name:</strong> ${student.firstName} ${student.middleName} ${student.lastName}</p>
          <p><strong>Student ID:</strong> ${student.studentId}</p>
          <p><strong>For the month of:</strong> ${currentMonth}</p>
        </div>
        <div style="width: 50%; float: left;">
          <p><strong>Receipt No:</strong> ${selectedClass.class_name}-${receiptNo}</p>
          <p><strong>Date of Issue:</strong> ${date}</p>
          <p><strong>Class:</strong> ${selectedClass.class_name}</p>
        </div>
        <table style="clear: both; width: 100%; border-collapse: collapse;">
          <thead>
            <tr>
              <th style="border: 1px solid black;">SN</th>
              <th style="border: 1px solid black;">Fees Description</th>
              <th style="border: 1px solid black;">Amount Nrs.</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="border: 1px solid black;">1</td>
              <td style="border: 1px solid black;">Monthly Fee</td>
              <td style="border: 1px solid black;">Rs.${studentFees[student.id]?.monthlyFee || 0}</td>
            </tr>
            <tr>
              <td style="border: 1px solid black;">2</td>
              <td style="border: 1px solid black;">Tiffin</td>
              <td style="border: 1px solid black;">Rs.${studentFees[student.id]?.tiffin || 0}</td>
            </tr>
            <tr>
              <td style="border: 1px solid black;">3</td>
              <td style="border: 1px solid black;">Vehicle Charge</td>
              <td style="border: 1px solid black;">Rs.${studentFees[student.id]?.vehicleCharge || 0}</td>
            </tr>
            <tr>
              <td style="border: 1px solid black;">4</td>
              <td style="border: 1px solid black;">Others</td>
              <td style="border: 1px solid black;">Rs.${studentFees[student.id]?.others || 0}</td>
            </tr>
            <tr>
              <td style="border: 1px solid black;" colspan="2">Total</td>
              <td style="border: 1px solid black;">Rs.${(
        (parseFloat(studentFees[student.id]?.monthlyFee || 0) +
          parseFloat(studentFees[student.id]?.tiffin || 0) +
          parseFloat(studentFees[student.id]?.vehicleCharge || 0) +
          parseFloat(studentFees[student.id]?.others || 0))
          .toFixed(2))
      }</td>
            </tr>
            <tr>
              <td style="border: 1px solid black;" colspan="2">Dues</td>
              <td style="border: 1px solid black;">Rs.${parseFloat(studentDues[student.studentId] || 0).toFixed(2)}</td>
            </tr>
            <tr>
              <td style="border: 1px solid black;" colspan="2">Overall Total</td>
              <td style="border: 1px solid black;">
                Rs.${(
        (parseFloat(studentFees[student.id]?.monthlyFee || 0) +
          parseFloat(studentFees[student.id]?.tiffin || 0) +
          parseFloat(studentFees[student.id]?.vehicleCharge || 0) +
          parseFloat(studentFees[student.id]?.others || 0) +
          parseFloat(studentDues[student.studentId] || 0))
          .toFixed(2))
      }
              </td>
            </tr>
          </tbody>
        </table>
        <div style="height: 50px;"></div>
        <div style="margin-top: 10px; border-top: 1px solid black; width:20%; float:right;"></div>
        <div style="margin-top: 20px;">
          <p style="font-size: 12px; text-align: right; margin-bottom: 5px;">For Rosemary</p>
        </div>
      </div>
    `;
    const formsHtml = students.map((student, index) => generateForm(student, index + 1)).join('');

    const container = document.createElement('div');
    container.innerHTML = formsHtml;
    document.body.appendChild(container);

    const pdf = new jsPDF('landscape', 'mm', 'a4');
    const forms = container.children;
    const pageHeight = pdf.internal.pageSize.getHeight();
    const pageWidth = pdf.internal.pageSize.getWidth();

    let currentRow = 0;
    let currentCol = 0;
    for (let i = 0; i < forms.length; i++) {
      if (i > 0 && i % 6 === 0) {
        pdf.addPage();
        currentRow = 0;
        currentCol = 0;
      }

      const canvas = await html2canvas(forms[i]);
      const imgData = canvas.toDataURL('image/png');
      const xPos = currentCol * (pageWidth / 3);
      const yPos = currentRow * (pageHeight / 2);

      pdf.addImage(imgData, 'PNG', xPos, yPos, pageWidth / 3, pageHeight / 2);

      currentCol++;
      if (currentCol > 2) {
        currentCol = 0;
        currentRow++;
      }
    }

    const pdfBlob = pdf.output('blob');
    setPdfBlob(URL.createObjectURL(pdfBlob));
    document.body.removeChild(container);

    const feeData = students.map(student => ({
      class_name: selectedClass.class_name,
      student_id: student.studentId,
      receipt_no: `${selectedClass.class_name}-${receiptNo++}`,
      monthly_fee: studentFees[student.id]?.monthlyFee || 0,
      tiffin: studentFees[student.id]?.tiffin || 0,
      vehicle_charge: studentFees[student.id]?.vehicleCharge || 0,
      others: studentFees[student.id]?.others || 0,
      previous_dues: studentDues[student.studentId] || 0,
      overall_total: (
        parseFloat(studentFees[student.id]?.monthlyFee || 0) +
        parseFloat(studentFees[student.id]?.tiffin || 0) +
        parseFloat(studentFees[student.id]?.vehicleCharge || 0) +
        parseFloat(studentFees[student.id]?.others || 0) +
        parseFloat(studentDues[student.studentId] || 0)
      ).toFixed(2),
      fee_month: currentMonth
    }));

    console.log('Fee data being sent:', JSON.stringify(feeData, null, 2));

    try {
      const response = await fetch('https://rosemary.bluebugsoft.com/rosemary_db/save_fees.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(feeData)
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
      }

      const result = await response.json();

      if (result.success) {
        alert('Fees saved successfully');
      } else if (result.message === 'Duplicate entries found') {
        const confirmUpdate = window.confirm(
          'Duplicate entries found for some students. Do you want to update their data?'
        );
        if (confirmUpdate) {
          // If user confirms, send another request to update the data
          const updateResponse = await fetch('https://rosemary.bluebugsoft.com/rosemary_db/update_fees.php', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(result.duplicates)
          });
          const updateResult = await updateResponse.json();
          if (updateResult.success) {
            alert('Fees updated successfully');
          } else {
            alert(`Error updating fees: ${updateResult.message}`);
          }
        }
      } else {
        alert(`Error saving fees: ${result.message}`);
      }
    } catch (error) {
      console.error('Error in handleGenerateBills:', error);
      alert(`Error saving fees: ${error.message}`);
    }
  };

  return (
    <div className='fee-page'>
      <div className='fix-height box data-coll'>
        <h1 className='main-heading'>Fees Management</h1>
        <select className='form-control' onChange={(e) => handleSelectClass(JSON.parse(e.target.value))}>
          <option value="" >Select Class</option>
          {classes.map((classItem, index) => (
            <option key={index} value={JSON.stringify(classItem)}>
              {classItem.class_name}
            </option>
          ))}
        </select>

        {selectedClass && (
          <div>
            <h2 className=''style={{marginTop:'10px'}}> {selectedClass.class_name}</h2>
            {students.map(student => (
              <div key={student.id}>
                <h3 className='sub-heading'>{student.firstName} {student.middleName} {student.lastName}</h3>
                <div className='form-row'>
                  <div className="form-group">


                    <label>Monthly Fee: </label>
                    <input
                      type="number" className='form-control'
                      value={studentFees[student.id]?.monthlyFee || ''}
                      onChange={(e) => handleFeeChange(student.id, 'monthlyFee', e.target.value)}
                    />
                  </div>


                  <div className="form-group">
                    <label>Tiffin: </label>
                    <input
                      type="number" className='form-control'
                      value={studentFees[student.id]?.tiffin || ''}
                      onChange={(e) => handleFeeChange(student.id, 'tiffin', e.target.value)}
                    />
                  </div>
                </div>

                <div className='form-row'>
                  <div className="form-group">
                    <label>Vehicle Charge: </label>
                    <input
                      type="number" className='form-control'
                      value={studentFees[student.id]?.vehicleCharge || ''}
                      onChange={(e) => handleFeeChange(student.id, 'vehicleCharge', e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Others: </label>
                    <input
                      type="number" className='form-control'
                      value={studentFees[student.id]?.others || ''}
                      onChange={(e) => handleFeeChange(student.id, 'others', e.target.value)}
                    />
                  </div>
                </div>

                <div className='form-row'>
                  <div className="form-group">
                    <label>Previous Dues: </label>
                  </div>
                  <div className="form-group">
                    <input
                      type="number" className='form-control'
                      value={studentDues[student.studentId] || 0}
                      readOnly
                    />


                  </div>
                </div>
              </div>
            ))}
            <button className='badge bg-success' onClick={handleGenerateBills}>Generate and Save Bills</button>

          </div>
        )}
      </div>
      <div className='fix-height box data-pdf'>
        <h3>Fee bill PDF Preview and print</h3>
        {pdfBlob && (
          <div className='fix-height'>
            <h3 className='main-heading'>Generated PDF</h3>
            <embed src={pdfBlob} type="application/pdf" width="100%" />
          </div>
        )}
      </div>
    </div >
  );
};

export default Fees;
import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const MyCalendar = () => {
  const [date, setDate] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [newEventTitle, setNewEventTitle] = useState('');
  const [editEventIndex, setEditEventIndex] = useState(-1); // Index of event being edited

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      console.log("Fetching events from:", 'https://rosemary.bluebugsoft.com/rosemary_db/getEvents.php');
      const response = await fetch('https://rosemary.bluebugsoft.com/rosemary_db/getEvents.php');
      const result = await response.json();
      console.log("Fetched events:", result);
      setEvents(result.events.map(event => ({
        ...event,
        date: new Date(event.date), // Convert date string to Date object
      })));
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  const addEventToDate = async () => {
    if (!newEventTitle.trim()) {
      alert('Please enter an event title.');
      return;
    }
    const eventDate = date.toISOString().split('T')[0];
    try {
      const existingEventIndex = events.findIndex(event => event.date.toISOString().split('T')[0] === eventDate);
      if (existingEventIndex !== -1) {
        const response = await fetch('https://rosemary.bluebugsoft.com/rosemary_db/updateEvent.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: events[existingEventIndex].id,
            date: eventDate,
            title: newEventTitle,
          }),
        });
        const result = await response.json();
        console.log("Event updated:", result);
      } else {
        const response = await fetch('https://rosemary.bluebugsoft.com/rosemary_db/addEvents.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            date: eventDate,
            title: newEventTitle,
          }),
        });
        const result = await response.json();
        console.log("Event added:", result);
      }
      fetchEvents();
      setNewEventTitle('');
      setEditEventIndex(-1);
    } catch (error) {
      console.error('Error adding/updating event:', error);
    }
  };

  const updateEvent = async (index) => {
    if (!newEventTitle.trim()) {
      alert('Please enter an event title.');
      return;
    }
    try {
      const response = await fetch('https://rosemary.bluebugsoft.com/rosemary_db/updateEvents.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: events[index].id,
          date: date.toISOString().split('T')[0],
          title: newEventTitle,
        }),
      });
      const result = await response.json();
      console.log("Event updated:", result);
      fetchEvents();
      setNewEventTitle('');
      setEditEventIndex(-1);
    } catch (error) {
      console.error('Error updating event:', error);
    }
  };

  const removeEvent = async (index) => {
    try {
      const response = await fetch('https://rosemary.bluebugsoft.com/rosemary_db/deleteEvents.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: events[index].id }),
      });
      const result = await response.json();
      console.log("Event deleted:", result);
      fetchEvents();
    } catch (error) {
      console.error('Error deleting event:', error);
    }
  };

  const onChange = (newDate) => {
    setDate(newDate);
    setEditEventIndex(-1); // Reset edit mode when date changes
  };

  const hasEvent = (checkDate) => {
    return events.some(event => event.date.toISOString().split('T')[0] === checkDate.toISOString().split('T')[0]);
  };

  return (
    <div className='calendar-page'>
      <div className='calendar-row'>
        <div className='choose-date box'>
          <Calendar
            onChange={onChange}
            value={date}
            tileContent={({ date }) =>
              hasEvent(date) && (
                <p style={{ margin: 0, fontSize: '14px' }}>
                  {events.find(event => event.date.toISOString().split('T')[0] === date.toISOString().split('T')[0])?.title}
                </p>
              )
            }
          />
          <div className='add-event'>
            <h3 className='main-heading'>Add/Edit Event</h3>
            <input
              type="text" className='form-control'
              placeholder="Event Title"
              value={newEventTitle}
              onChange={(e) => setNewEventTitle(e.target.value)}
            />
            <button style={{ marginTop: '20px' }} className='badge bg-success' onClick={editEventIndex !== -1 ? () => updateEvent(editEventIndex) : addEventToDate}>
              {editEventIndex !== -1 ? 'Update Event' : 'Add Event to Date'}
            </button>
          </div>
        </div>
        <div className='event-list box'>
          <h3 className='main-heading'>List Of Events</h3>
          <ul>
            {events.map((event, index) => (
              <li key={event.id} className='event-item'>
                <span className='event-date'>{new Date(event.date).toLocaleDateString()}</span>
                {editEventIndex === index ? (
                  <>
                    <input
                      type="text" className='form-control'
                      value={newEventTitle}
                      onChange={(e) => setNewEventTitle(e.target.value)}
                    />
                    <button className='badge bg-success' onClick={() => updateEvent(index)}>Save</button>
                  </>
                ) : (
                  <>
                    {event.title}
                    <div className='event-buttons' style={{ margin: '10px 0px' }}>
                      <button className='badge bg-success' onClick={() => setEditEventIndex(index)}>Edit</button>
                      <button style={{ marginLeft: '15px' }} className='badge bg-danger' onClick={() => removeEvent(index)}>Remove</button>
                    </div>
                  </>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MyCalendar;

import React, { useState, useEffect } from 'react';

const BalanceSheet = () => {
  const [students, setStudents] = useState([]);
  const [classes, setClasses] = useState([]);
  const [selectedStudentId, setSelectedStudentId] = useState('');
  const [selectedStudentName, setSelectedStudentName] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [balanceSheetData, setBalanceSheetData] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  // Fetch classes when component mounts
  useEffect(() => {
    fetch('https://rosemary.bluebugsoft.com/rosemary_db/get_classes.php')
      .then(response => response.json())
      .then(data => setClasses(data))
      .catch(error => console.error('Error fetching classes:', error));
  }, []);

  // Fetch students for the selected class
  useEffect(() => {
    if (selectedClass) {
      fetch(`https://rosemary.bluebugsoft.com/rosemary_db/get_students.php?className=${encodeURIComponent(selectedClass)}`)
        .then(response => response.json())
        .then(data => setStudents(data))
        .catch(error => console.error('Error fetching students:', error));
    } else {
      setStudents([]); // Clear students if no class is selected
    }
  }, [selectedClass]);

  const handleSearch = () => {
    setLoading(true);
    let url = 'https://rosemary.bluebugsoft.com/rosemary_db/get_balance_sheet.php?';
    if (selectedStudentId) {
      url += `student_id=${encodeURIComponent(selectedStudentId)}`;
    } else if (selectedStudentName && selectedClass) {
      url += `class_name=${encodeURIComponent(selectedClass)}&student_name=${encodeURIComponent(selectedStudentName)}`;
    } else if (selectedClass) {
      url += `class_name=${encodeURIComponent(selectedClass)}`;
    } else {
      setError('Please select a class or enter a student ID.');
      setLoading(false);
      return;
    }

    fetch(url)
      .then(response => response.json())
      .then(data => {
        setBalanceSheetData(data);
        setLoading(false);
      })
      .catch(error => {
        setError('Failed to fetch balance sheet');
        setLoading(false);
      });
  };

  const printBalanceSheet = () => {
    const printContent = `
      <div class="header">
        <img src="https://rosemary.bluebugsoft.com/rosemary_db/Rosemary.png" alt="Brand Logo" />
        <h1>Rosemary Pre School</h1>
        <p>Email: school@email.com | Phone: 01-4450570,9843233371</p>
        <p>Address: Kageshwori Manahara, Danchhi, Kathmandu</p>
        <h2>Fee Receipt</h2>
        <p><strong>Class:</strong> ${selectedClass || 'N/A'}</p>
        <p><strong>Student ID:</strong> ${selectedStudentId || 'N/A'}</p>
      </div>
      <table>
        <thead>
          <tr>
            <th>Student ID</th>
            <th>Class</th>
            <th>Fee Month</th>
            <th>Total Fee</th>
            <th>Paid Amount</th>
            <th>Balance Due</th>
          </tr>
        </thead>
        <tbody>
          ${balanceSheetData.length > 0
        ? balanceSheetData.map(item => `
              <tr>
                <td>${item.student_id}</td>
                <td>${item.class_name}</td>
                <td>${item.fee_month}</td>
                <td>${item.total_fee}</td>
                <td>${item.total_paid}</td>
                <td>${item.balance_due}</td>
              </tr>
            `).join('')
        : '<tr><td colspan="6">No data available</td></tr>'
      }
        </tbody>
      </table>
    `;

    // Create a hidden frame
    const printFrame = document.createElement('iframe');
    printFrame.style.position = 'absolute';
    printFrame.style.width = '0';
    printFrame.style.height = '0';
    printFrame.style.border = 'none';
    document.body.appendChild(printFrame);

    // Write content to the frame
    const printDoc = printFrame.contentWindow.document;
    printDoc.open();
    printDoc.write(`
      <html>
      <head>
      <title>Receipt Print</title>
        <style>
          body { font-family: Arial, sans-serif; }
          .header { text-align: center; margin-bottom: 20px; }
          .header img { max-width: 150px; }
          .header h1, .header h2 { margin: 0; }
          .header p { margin: 5px 0; }
          table { width: 100%; border-collapse: collapse; margin-top: 20px; }
          th, td { border: 1px solid black; padding: 8px; text-align: left; }
          th { background-color: #f2f2f2; }
          @media print {
            /* Hide the header and footer for print */
            .no-print { display: none; }
          }
        </style>
      </head>
      <body>
        ${printContent}
      </body>
      </html>
    `);
    printDoc.close();

    // Print the frame content
    printFrame.contentWindow.focus();
    printFrame.contentWindow.print();

    // Remove the frame after printing
    document.body.removeChild(printFrame);
  };


  return (
    <div className='balance-page box'>
      <h1 className='main-heading'>Balance Sheet</h1>
      <div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="class">Class:</label>
            <select className='form-control'
              id="class"
              value={selectedClass}
              onChange={(e) => setSelectedClass(e.target.value)}
            >
              <option value="">Select Class</option>
              {classes.map((cls) => (
                <option key={cls.class_name} value={cls.class_name}>{cls.class_name}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="student">Student:</label>
            <select
              id="student"
              className='form-control'
              value={selectedStudentId}
              onChange={(e) => setSelectedStudentId(e.target.value)}
            >
              <option value="">Select a student</option>
              {students.map((student) => (
                <option key={student.studentId} value={student.studentId}>
                  {student.firstName} {student.middleName} {student.lastName}
                </option>
              ))}
            </select>
          </div>
        </div>

        <button className='badge bg-success' onClick={handleSearch} disabled={loading}>Search</button>
        <button className='badge bg-warning' style={{ marginLeft: '10px', color: 'black' }} onClick={printBalanceSheet}>Print</button>
      </div>

      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}

      <table className="table" style={{ marginTop: '30px' }}>
        <thead>
          <tr>
            <th>Student ID</th>
            <th>Class</th>
            <th>Fee Month</th>
            <th>Total Fee</th>
            <th>Paid Amount</th>
            <th>Balance Due</th>
          </tr>
        </thead>
        <tbody>
          {balanceSheetData.length > 0 ? (
            balanceSheetData.map((item, index) => (
              <tr key={index}>
                <td>{item.student_id}</td>
                <td>{item.class_name}</td>
                <td>{item.fee_month}</td>
                <td>{item.total_fee}</td>
                <td>{item.total_paid}</td>
                <td>{item.balance_due}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">No data available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default BalanceSheet;

// ClassList.js

import React from 'react';

const ClassList = ({ classes, onViewClass }) => (
  <div className="class-list" >
    <ul>
      {classes.map((cls, index) => (
        <li style={{ width:'70%',marginTop: '15px', display:'flex', justifyContent:'space-between' }} key={index}>
          <span className='sub-heading'>{cls.className}</span>
          <span className='sub-heading'>{cls.totalStudents} Students</span>
          <button className="badge bg-warning " style={{ color: 'black', marginLeft: '15px' }} onClick={() => onViewClass(cls)}>View</button>
        </li>
      ))}
    </ul>
  </div>
);

export default ClassList;
import React from 'react';
import ReactModal from 'react-modal';

const ConfirmationModal = ({ isOpen, onRequestClose, onConfirm, title, message }) => (

  <ReactModal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    contentLabel="Confirmation Modal"
    ariaHideApp={false}
  >

    <div className="modal-content">
      <h2 className='main-cotent'>{title}</h2>
      <p>{message}</p>
      <div className='btn-row'>
        <button class=" badge bg-success" onClick={onConfirm}>Yes</button>
        <button class=" badge bg-danger" onClick={onRequestClose}>No</button>
      </div>
    </div>

  </ReactModal>

);

export default ConfirmationModal;

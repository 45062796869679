// src/components/Sidebar.js
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTableColumns, faChildren, faAddressBook, faChalkboardTeacher, faFileInvoiceDollar, faUsers,faFileInvoice, faCalendarDay, faBoxArchive, faRightFromBracket, faReceipt } from '@fortawesome/free-solid-svg-icons';
import brand from '../../images/Rosemary.png';
import { useAuth } from '../../context/AuthContext';

const Sidebar = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const handleBackup = () => {
    window.location.href = 'https://rosemary.bluebugsoft.com/rosemary_db/backup.php';
  };

  const handleLogout = () => {
    logout();
  };
  return (
    <div className="sidebar">
      <div className='top-side-bar'>
        <div className="logo" alt="logo">
          <img src={brand} alt="Brand Logo" />
        </div>
        <ul>
          <li>
            <NavLink className='nav-item' to="/" end>
              <FontAwesomeIcon className='i' icon={faTableColumns} /> Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink className='nav-item' to="/students">
              <FontAwesomeIcon className='i' icon={faChildren} /> Students
            </NavLink>
          </li>
          <li>
            <NavLink className='nav-item' to="/enrollment">
              <FontAwesomeIcon className='i' icon={faAddressBook} />
              Enrollment
            </NavLink>
          </li>
          <li>
            <NavLink className='nav-item' to="/class">
              <FontAwesomeIcon className='i' icon={faChalkboardTeacher} /> Class
            </NavLink>
          </li>
          <li>
            <NavLink className='nav-item' to="/fees">
              <FontAwesomeIcon className='i' icon={faFileInvoiceDollar} /> Fees
            </NavLink>
          </li>
          <li>
            <NavLink className='nav-item' to="/billing">
              <FontAwesomeIcon className='i' icon={faReceipt} /> Billing
            </NavLink>
          </li>
          <li>
            <NavLink className='nav-item' to="/users">
              <FontAwesomeIcon className='i' icon={faUsers} /> Users
            </NavLink>
          </li>
          <li>
            <NavLink className='nav-item' to="/balancesheet">
              <FontAwesomeIcon className='i' icon={faFileInvoice} /> Balance Sheet
            </NavLink>
          </li>
          <li>
            <NavLink className='nav-item' to="/calendar">
              <FontAwesomeIcon className='i' icon={faCalendarDay} /> Calendar
            </NavLink>
          </li>
        </ul>
      </div>

      <div className="settings">
        <button onClick={handleBackup}><FontAwesomeIcon className='i' icon={faBoxArchive} /> Backup</button>
        <button onClick={handleLogout}><FontAwesomeIcon className='i' icon={faRightFromBracket} /> Logout</button>
      </div>
    </div>
  );
};

export default Sidebar;

import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation,Navigate   } from "react-router-dom";
import { AuthProvider,useAuth } from './context/AuthContext';
import './bootstrap.min.css'

import Navbar from "./components/navbar/Navbar";
import Home from "./pages/home/Home";
import Dashboard from "./pages/dashboard/Dashboard";
import Student from "./pages/sudent/Student";
import Class from "./pages/class/Class";
import Enrollment from "./pages/enrollment/Enrollment"
import User from "./pages/user/User";
import Calendar from "./pages/calendar/Calendar";
import Fees from "./pages/fees/Fees";
import Users from "./pages/user/User";
import Billing from "./pages/billing/Billing";
import BalanceSheet from "./pages/balance/BalanceSheet";
import Login from './pages/login/Login';
import ProtectedRoute from './components/ProtectedRoute';



// Main App Component
function App() {
  return (
    <Router>
        <AuthProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/*" element={<ProtectedLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="students" element={<Student />} />
            <Route path="enrollment" element={<Enrollment />} />
            <Route path="class" element={<Class />} />
            <Route path="fees" element={<Fees />} />
            <Route path="billing" element={<Billing />} />
            <Route path="users" element={<User />} />
            <Route path="calendar" element={<Calendar />} />
            <Route path="balancesheet" element={<BalanceSheet />} />
          </Route>
        </Routes>
    </AuthProvider>
      </Router>
  );
}

// ProtectedLayout component to conditionally render the sidebar
function ProtectedLayout() {
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  
  if (!isAuthenticated && location.pathname !== '/login') {
    return <Navigate to="/login" />;
  }

  return (
    <div className="App">
      {location.pathname !== '/login' && (
        <div className="sideBar">
          <Navbar />
        </div>
      )}
      <div className="mainContent">
        <Routes>
          <Route index element={<Dashboard />} />
          <Route path="students" element={<Student />} />
          <Route path="enrollment" element={<Enrollment />} />
          <Route path="class" element={<Class />} />
          <Route path="fees" element={<Fees />} />
          <Route path="billing" element={<Billing />} />
          <Route path="users" element={<User />} />
          <Route path="calendar" element={<Calendar />} />
          <Route path="balancesheet" element={<BalanceSheet />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import brand from '../../images/Rosemary.png';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null); // For displaying error messages
  const { login } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is already logged in when the component mounts
    const storedUsername = sessionStorage.getItem('username');
    if (storedUsername) {
      login(); // Assume login function sets the user as authenticated
      navigate('/'); // Redirect to home or dashboard
    }

    const handleBeforeUnload = () => {
      // Clear the session storage when the tab is closed
      sessionStorage.removeItem('username');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [login, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null); // Reset error message

    try {
      // Make a POST request to the PHP script for authentication
      const response = await fetch('https://rosemary.bluebugsoft.com/rosemary_db/authentication.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          username,
          password,
        }),
      });

      const result = await response.json();

      if (result.success) {
        // Store username in sessionStorage
        sessionStorage.setItem('username', username);
        login(); // Assuming login is a function that sets user authentication state
        navigate('/');
      } else {
        setError(result.message || 'Invalid username or password'); // Display error message from server
      }
    } catch (error) {
      setError('An error occurred. Please try again.'); // Display generic error message
    }
  };

  return (
    <div className='login-page'>
      <div className='login-inner box'>
        <div className='login-header'>
          <h2 className='main-heading'>Login</h2>
          <img src={brand} alt="Brand Logo" />
        </div>

        {error && <p style={{ color: 'red' }}>{error}</p>} {/* Display error message */}
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="username">Username:</label>
              <input
                className='form-control'
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="password">Password:</label>
              <input
                className='form-control'
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
          </div>

          <button className='badge bg-success' type="submit">Login</button>
        </form>
      </div>
    </div>
  );
}

export default Login;

// AdminUserOverview.js

import React, { useState, useEffect } from 'react';
import ConfirmationModal from './ConfirmationModal';

const roles = ['Admin', 'Principal', 'Accountant', 'Coordinator'];

const AdminUserOverview = ({ selectedUser, onDelete, onUpdate }) => {
  const [userData, setUserData] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    role: roles[0]
  });

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

  useEffect(() => {
    if (selectedUser) {
      setUserData({
        firstName: selectedUser.firstName,
        middleName: selectedUser.middleName,
        lastName: selectedUser.lastName,
        role: selectedUser.role
      });
    }
  }, [selectedUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    setIsUpdateModalOpen(true);
  };

  const confirmUpdate = async () => {
    try {
      const response = await fetch('https://rosemary.bluebugsoft.com/rosemary_db/update_user.php', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: selectedUser.id,
          firstName: userData.firstName,
          middleName: userData.middleName,
          lastName: userData.lastName,
          role: userData.role
        })
      });
  
      const result = await response.json();
      if (result.success) {
        onUpdate({
          id: selectedUser.id,
          name: `${userData.firstName} ${userData.middleName} ${userData.lastName}`,
          role: userData.role
        });
        setIsUpdateModalOpen(false);
      } else {
        console.error('Update failed:', result.message);
        // Optionally display an error message to the user
      }
    } catch (error) {
      console.error('Error updating user:', error);
      // Optionally display an error message to the user
    }
  };
  

  const handleDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await fetch(`https://rosemary.bluebugsoft.com/rosemary_db/delete_user.php?id=${selectedUser.id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      const result = await response.json();
      if (result.success) {
        onDelete(selectedUser.id); // Notify parent component about the deletion
        setIsDeleteModalOpen(false);
      } else {
        console.error('Delete failed:', result.message);
        // Optionally display an error message to the user
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      // Optionally display an error message to the user
    }
  };
  

  return (
    <div className="admin-user-overview box">
      <h2 className='main-heading'>Admin Users Overview</h2>
      <form onSubmit={handleUpdate}>
        <div className='form-row'>
          <div className="form-group">
            <label>First Name</label> <br></br>
            <input type="text" className='form-control' name="firstName" value={userData.firstName} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>Middle Name</label> <br></br>
            <input type="text" className='form-control' name="middleName" value={userData.middleName} onChange={handleChange} />
          </div>
        </div>
        <div className='form-row'>
          <div className="form-group">
            <label>Last Name</label>
            <input type="text" className='form-control' name="lastName" value={userData.lastName} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>Role</label>
            <select name="role" className='form-control' value={userData.role} onChange={handleChange}>
              {roles.map((role, index) => (
                <option key={index} value={role}>{role}</option>
              ))}
            </select>
          </div>
        </div>
        <button type="button" className="badge bg-danger" onClick={handleDelete} style={{ marginRight: '10px' }}>Delete</button>
        <button type="submit" className='badge bg-success'>Update</button>
      </form>
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        onConfirm={confirmDelete}
        title="Confirm Deletion"
        message="Are you sure you want to delete this user?"
      />
      <ConfirmationModal
        isOpen={isUpdateModalOpen}
        onRequestClose={() => setIsUpdateModalOpen(false)}
        onConfirm={confirmUpdate}
        title="Confirm Update"
        message="Are you sure you want to update this user data?"
      />
    </div>
  );
};

export default AdminUserOverview;

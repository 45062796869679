import React, { useState, useEffect } from 'react';
import ClassList from '../../components/ClassList';
import ClassOverview from '../../components/ClassOverview';
import AddClassModal from '../../components/AddClassModal';

const Class = () => {
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editClass, setEditClass] = useState(null);

  useEffect(() => {
    // Fetch classes data when the component mounts
    const fetchClasses = async () => {
      try {
        const response = await fetch('https://rosemary.bluebugsoft.com/rosemary_db/getClasses.php');
        const result = await response.json();
        setClasses(result.classes);
      } catch (error) {
        console.error('Error fetching classes:', error);
      }
    };

    fetchClasses();
  }, []);

  const handleViewClass = (classData) => {
    setSelectedClass(classData);
  };

  const handleAddClass = (newClass) => {
    if (editClass) {
      // Update class in state
      setClasses(classes.map(cls =>
        cls.id === editClass.id ? { ...cls, className: newClass.className } : cls
      ));
      
    } else {
      // Add new class to state
      setClasses([...classes, { ...newClass, id: classes.length + 1 }]); // Temporary ID assignment for new class
      
    }
    setEditClass(null); // Reset edit class
    setIsModalOpen(false);
  };

  const handleEditClass = (classData) => {
    setEditClass(classData);
    setIsModalOpen(true);
  };

  const handleDeleteClass = async (classData) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this class?');
    if (confirmDelete) {
      try {
        console.log('Attempting to delete class:', classData);
        const response = await fetch('https://rosemary.bluebugsoft.com/rosemary_db/deleteClass.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ class_name: classData.className }),
        });

        console.log('Response status:', response.status);
        const result = await response.json();
        console.log('Response data:', result);

        if (result.success) {
          console.log('Deletion successful, updating state');
          setClasses(classes.filter(cls => cls.className !== classData.className));
          setSelectedClass(null);
          alert('Class deleted successfully.');
        } else {
          console.error('Deletion failed:', result.message);
          alert(`Failed to delete class: ${result.message}`);
        }
      } catch (error) {
        console.error('Error deleting class:', error);
        alert('An error occurred while deleting the class. Please try again.');
      }
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditClass(null); // Reset edit class when closing the modal
  };

  return (
    <div className="class-page fix-height box">
      <div className="class-list-section">
        <button className="badge bg-success" onClick={openModal}>Add Class</button>
        <ClassList classes={classes} onViewClass={handleViewClass} />
      </div>
      {selectedClass && (
        <ClassOverview
          selectedClass={selectedClass}
          onEditClass={handleEditClass}
          onDeleteClass={handleDeleteClass}
        />
      )}
      <AddClassModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        onAddClass={handleAddClass}
        editClass={editClass}
      />
    </div>
  );
};

export default Class;

import React, { useState, useEffect } from 'react';

const StudentPreview = ({ student }) => {
  const [photoURL, setPhotoURL] = useState(null);

  useEffect(() => {
    if (student && student.photo) {
      const url = URL.createObjectURL(student.photo);
      setPhotoURL(url);

      // Clean up the URL when the component unmounts
      return () => URL.revokeObjectURL(url);
    } else {
      setPhotoURL(null);
    }
  }, [student]);

  return (
    <div className="student-preview box fix-height">
      <h2 className='main-heading'>Student Details Preview</h2>
      <div className="profile-item">
        <div className='profile'>
          {photoURL ? (
            <img src={photoURL} alt="Student Profile" />
          ) : (
            <img src="https://via.placeholder.com/150" alt="Student Profile" />
          )}
        </div>
        <div className="student-details">
          <p><strong>Student ID:</strong> {student.studentId}</p>
          <p><strong>First Name:</strong> {student.firstName}</p>
          <p><strong>Middle Name:</strong> {student.middleName}</p>
          <p><strong>Last Name:</strong> {student.lastName}</p>
          <p><strong>Date of Birth:</strong> {student.dateOfBirth}</p>
          <p><strong>Gender:</strong> {student.gender}</p>
          <p><strong>Class:</strong> {student.className}</p>
          <p><strong>Father's Name:</strong> {student.fathersName}</p>
          <p><strong>Mother's Name:</strong> {student.mothersName}</p>
          <p><strong>Address:</strong> {student.address}</p>
          <p><strong>Phone Number:</strong> {student.phoneNumber}</p>
          <p><strong>Alternative Phone Number:</strong> {student.alternativePhoneNumber}</p>
          <p><strong>Email:</strong> {student.email}</p>
        </div>
      </div>
    </div>
  );
};

export default StudentPreview;

import React, { useState, useEffect } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import brand from '../../images/Rosemary.png';

const Billing = () => {
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedStudent, setSelectedStudent] = useState('');
  const [feeMonth, setFeeMonth] = useState('');
  const [fees, setFees] = useState([]);
  const [receiptNo, setReceiptNo] = useState('');
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState({
    name: '',
    paidAmount: '',
    paymentMethod: 'cash'
  });
  const [pdfPreview, setPdfPreview] = useState('');
  const [showPdfPreview, setShowPdfPreview] = useState(false);
  const [billNo, setBillNo] = useState(675); // Starting bill number

  useEffect(() => {
    // Fetch classes when component mounts
    fetch('https://rosemary.bluebugsoft.com/rosemary_db/get_classes.php')
      .then(response => response.json())
      .then(data => setClasses(data))
      .catch(error => console.error('Error fetching classes:', error));
  }, []);

  useEffect(() => {
    if (selectedClass) {
      // Fetch students for the selected class
      fetch(`https://rosemary.bluebugsoft.com/rosemary_db/get_students.php?className=${selectedClass}`)
        .then(response => response.json())
        .then(data => setStudents(data))
        .catch(error => console.error('Error fetching students:', error));
    }
  }, [selectedClass]);

  const handleSearch = () => {
    if (receiptNo) {
      fetch(`https://rosemary.bluebugsoft.com/rosemary_db/get_fee_details.php?receiptNo=${receiptNo}`)
        .then(response => response.json())
        .then(data => setFees(data))
        .catch(error => console.error('Error fetching fee details:', error));
    } else if (selectedStudent && feeMonth) {
      fetch(`https://rosemary.bluebugsoft.com/rosemary_db/get_fee_details.php?className=${selectedClass}&studentId=${selectedStudent}&feeMonth=${feeMonth}`)
        .then(response => response.json())
        .then(data => setFees(data))
        .catch(error => console.error('Error fetching fee details:', error));
    } else {
      alert('Please provide either a receipt number or select a class, student, and month.');
    }
  };

  const handlePayment = () => {
    if (fees.length > 0) {
      setShowPaymentForm(true);
    } else {
      alert('Please search for fee details before proceeding.');
    }
  };

  const handlePaymentChange = (e) => {
    const { name, value } = e.target;
    setPaymentDetails(prevDetails => ({
      ...prevDetails,
      [name]: value
    }));
  };

  const handlePaymentSubmit = (e) => {
    e.preventDefault();

    if (paymentDetails.name && paymentDetails.paidAmount && paymentDetails.paymentMethod) {
      generatePdfPreview();

      // Calculate balance due
      const totalFee = parseFloat(fees[0].monthly_fee) +
        parseFloat(fees[0].tiffin) +
        parseFloat(fees[0].vehicle_charge) +
        parseFloat(fees[0].others) +
        parseFloat(fees[0].previous_dues);
      const balanceDue = totalFee - parseFloat(paymentDetails.paidAmount);

      // Prepare payment data
      const paymentData = {
        student_id: fees[0].studentId,
        receipt_no: fees[0].receipt_no,
        bill_no: billNo,
        paid_amount: paymentDetails.paidAmount,
        payment_method: paymentDetails.paymentMethod,
        payment_date: new Date().toISOString().split('T')[0], // YYYY-MM-DD format
        paid_by: paymentDetails.name,
        balance_due: balanceDue.toFixed(2)
      };

     

      // Send payment data to PHP script
      fetch('https://rosemary.bluebugsoft.com/rosemary_db/insert_payment.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(paymentData)
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === 'success') {
            alert('Payment processed successfully!');
            setShowPaymentForm(false);
            // Increment bill number for the next bill
            setBillNo(prevBillNo => prevBillNo + 1);
          } else {
            alert(`Error: ${data.message}`);
          }
        })
        .catch(error => console.error('Error processing payment:', error));
    } else {
      alert('Please fill all payment details.');
    }
  };

  const generatePdfPreview = () => {
    // Create a container for HTML content
    const container = document.createElement('div');
    container.innerHTML = `
      <!DOCTYPE html>
      <html lang="en">
        <head>
          <meta charset="UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <title>Fee Receipt</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              font-size: 2rem;
              line-height: 1.4;
              margin: 0;
              padding: 20px;
            }
              p{
              font-size:1.2rem;
              }
            .container {
               width: calc(100%-20px); /* A4 width */
            height: calc(100%-20px); /* A4 height */
            padding: 20px; /* padding inside the container */
              padding: 20px;
              box-sizing: border-box;
            }
            .header {
              text-align: center;
              margin-bottom: 50px;
            }
              .header img{
              width:200px;}
            .header h1 {
              margin: 0;
              font-size: 2rem;
            }
            .header p {
              margin: 5px 0;
            }
            .student-info {
              display: flex;
              justify-content: space-between;
              margin-bottom: 20px;
            }
            .student-info div {
              width: 48%;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 20px;
              font-size: 1.2rem;
            }
            th,
            td {
              border: 1px solid #000;
              padding: 5px;
              text-align: left;
              
            }
            .footer {
              display: flex;
              justify-content: space-between;
              margin-top: 40px;
            }
            .school-copy {
              margin-top: 40px;
              border-top: 1px dashed #000;
              padding-top: 20px;
            }
            .twocol {
              display: flex;
              gap: 40%;
            }
            .sign{
            padding-top: 20px;
            border-top: 1px solid black;
            }
            .school-copy .footer{
            padding-top:50px;}
          </style>
        </head>
        <body>
          <div class="container">
            <div class="header">
              <img src="${brand}" alt="Brand Logo" />
              <h1>Rosemary Pre School</h1>
              <p>Email: school@email.com | Phone: 01-4450570,9843233371</p>
              <p>Address: Kageshwori Manahara, Danchhi, Kathmandu</p>
              <h2>Fee Receipt</h2>
            </div>
            <div class="student-info">
              <div>
                <p><strong>Student Name:</strong> ${fees[0].firstName} ${fees[0].middleName} ${fees[0].lastName}</p>
                <p><strong>Student ID:</strong> ${fees[0].studentId}</p>
                <p><strong>Fee For the month(s):</strong> ${fees[0].fee_month}</p>
              </div>
              <div>
                <p><strong>Date of Payment:</strong> ${new Date().toLocaleDateString()}</p>
                <p><strong>Class:</strong> ${fees[0].class_name}</p>
                <p><strong>Receipt no:</strong> ${fees[0].receipt_no}</p>
                <p><strong>Bill no:</strong> ${billNo}</p> 
                </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>SN</th>
                  <th>Fees Description</th>
                  <th>Amount Nrs.</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Monthly Fee</td>
                  <td>Rs. ${fees[0].monthly_fee}</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Tiffin</td>
                  <td>Rs. ${fees[0].tiffin}</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Vehicle Charge</td>
                  <td>Rs. ${fees[0].vehicle_charge}</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Others</td>
                  <td>Rs. ${fees[0].others}</td>
                </tr>
                <tr>
                  <td colspan="2">Total</td>
                  <td>Rs. ${(
        parseFloat(fees[0].monthly_fee) +
        parseFloat(fees[0].tiffin) +
        parseFloat(fees[0].vehicle_charge) +
        parseFloat(fees[0].others) +
        parseFloat(fees[0].previous_dues)
      ).toFixed(2)}</td>
                </tr>
                <tr>
                  <td colspan="2">Paid Amount</td>
                  <td>Rs. ${paymentDetails.paidAmount}</td>
                </tr>
                <tr>
                  <td colspan="2">Balance Due</td>
                  <td>Rs. ${((
        parseFloat(fees[0].monthly_fee) +
        parseFloat(fees[0].tiffin) +
        parseFloat(fees[0].vehicle_charge) +
        parseFloat(fees[0].others) +
        parseFloat(fees[0].previous_dues)
      ) - paymentDetails.paidAmount).toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
            <p><strong>Total Due Amount:</strong> Rs. ${((
        parseFloat(fees[0].monthly_fee) +
        parseFloat(fees[0].tiffin) +
        parseFloat(fees[0].vehicle_charge) +
        parseFloat(fees[0].others) +
        parseFloat(fees[0].previous_dues)
      ) - paymentDetails.paidAmount).toFixed(2)}</p>
            <p><strong>Paid Through:</strong> ${paymentDetails.paymentMethod}</p>
            <div class="footer">
              <p>Received With Thanks,</p>
              <div class="sign">
              <p>For Rosemary</p>
              </div>
            </div>
            <div class="school-copy">
               <div class="header">
               <img src="${brand}" alt="Brand Logo" />
              <h1>Rosemary Pre School</h1>
              <p>Email: school@email.com | Phone: 01-4450570,9843233371</p>
              <p>Address: Kageshwori Manahara, Danchhi, Kathmandu</p>
              <h2>Fee Receipt</h2>
            </div>
              <div class="twocol">
                <div class="row">
                  <p><strong>Receipt no:</strong> ${fees[0].receipt_no}</p>
                  <p><strong>Student Name:</strong> ${fees[0].firstName} ${fees[0].middleName} ${fees[0].lastName}</p>
                  <p><strong>Student ID:</strong> ${fees[0].studentId}</p>
                  <p><strong>Fee For the month(s):</strong> ${fees[0].fee_month}</p>
                  <p><strong>Date:</strong> ${new Date().toLocaleDateString()}</p>
                </div>
                <div class="row">
                  <p><strong>Bill no:</strong> ${billNo}</p> 
                  <p><strong>Paid By:</strong> ${paymentDetails.name}</p>
                  <p><strong>Paid Amount:</strong>Rs. ${paymentDetails.paidAmount}</p>
                  <p><strong>Payment Method:</strong>${paymentDetails.paymentMethod}</p>
                  <p><strong>Balance Due:</strong> Rs. ${((
        parseFloat(fees[0].monthly_fee) +
        parseFloat(fees[0].tiffin) +
        parseFloat(fees[0].vehicle_charge) +
        parseFloat(fees[0].others) +
        parseFloat(fees[0].previous_dues)
      ) - paymentDetails.paidAmount).toFixed(2)}</p>
                </div>
              </div>
              <div class="footer">
              <p>Received With Thanks,</p>
              <div class="sign">
              <p>For Rosemary</p>
              </div>
            </div>
            </div>
          </div>
        </body>
      </html>
    `;

    // Append container to the body
    document.body.appendChild(container);

    // Use html2canvas to capture the container
    html2canvas(container, { scale: 2 }).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgWidth = 210; // A4 width in mm
      const imgHeight = canvas.height * imgWidth / canvas.width;
      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      const pdfBlob = pdf.output('blob');
      const pdfUrl = URL.createObjectURL(pdfBlob);

      setPdfPreview(pdfUrl); // Set preview URL for display
      setShowPdfPreview(true); // Show the preview

      // Clean up the container
      document.body.removeChild(container);
    }).catch(error => {
      console.error('Error generating PDF:', error);
    });
  };

  return (
    <div className='billing-page-main'>
      <div className="billing-page fix-height box">
        <h2 className='main-heading'>Fee Billing Details</h2>

        <div className="search-form">
          
            <div className="form-group">
              <label>Receipt No:</label>
              <input className='form-control'
                type="text"
                value={receiptNo}
                onChange={(e) => setReceiptNo(e.target.value)}
              />
            </div>
            <p className="sub-heading" style={{textAlign:'center', margin:'0'}}>or,</p>
            <div className="form-group">

              <label>Select Class:</label>
              <select className='form-control'
                value={selectedClass} onChange={(e) => setSelectedClass(e.target.value)}
              >
                <option value="">Select a class</option>
                {classes.map((classItem) => (
                  <option key={classItem.id} value={classItem.class_name}>{classItem.class_name}</option>
                ))}
              </select>
            </div>
          

          <div className='form-row'>
            <div className="form-group">
              <label>Select Student:</label>
              <select className='form-control'
                value={selectedStudent}
                onChange={(e) => setSelectedStudent(e.target.value)}
              >
                <option value="">Select a student</option>
                {students.map((student) => (
                  <option key={student.id} value={student.studentId}>
                    {student.firstName} {student.middleName} {student.lastName}
                  </option>
                ))}
              </select>
            </div>


            <div className="form-group">

              <label>Fee Month:</label>
              <input className='form-control'
                type="text"
                value={feeMonth}
                onChange={(e) => setFeeMonth(e.target.value)}
              />
            </div>
          </div>

          <button className='badge bg-success' onClick={handleSearch}>Search</button>
        </div>

        {fees.length > 0 && (
          <div className="fees-table" style={{ marginTop: '20px', fontSize:'11px' }}>
            <h3 className='main-heading'>Fee Details</h3>
            <table className='table'>
              <thead>
                <tr>
                  <th>Receipt No</th>
                  <th style={{width:'20%'}}>Student Name</th>
                  <th>Monthly Fee</th>
                  <th>Tiffin</th>
                  <th>Vehicle Charge</th>
                  <th>Others</th>
                  <th>Dues</th>
                  <th>Total</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {fees.map((fee, index) => (
                  <tr key={index}>
                    <td>{fee.receipt_no}</td>
                    <td >{`${fee.firstName} ${fee.middleName} ${fee.lastName}`}</td>
                    <td>{fee.monthly_fee}</td>
                    <td>{fee.tiffin}</td>
                    <td>{fee.vehicle_charge}</td>
                    <td>{fee.others}</td>
                    <td>{fee.previous_dues}</td>
                    <td>
                      {(
                        parseFloat(fee.monthly_fee) +
                        parseFloat(fee.tiffin) +
                        parseFloat(fee.vehicle_charge) +
                        parseFloat(fee.others) +
                        parseFloat(fee.previous_dues)
                      ).toFixed(2)}
                    </td>
                    <td>
                      <button className='badge bg-success' onClick={handlePayment}>Pay Bill</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>




          </div>
        )}

        {showPaymentForm && (
          <div className="payment-form" style={{ marginTop: '20px' }}>
            <h3 className='main-heading'>Payment Details</h3>
            <form onSubmit={handlePaymentSubmit}>

              <div className='form-row'>
                <div className="form-group">

                  <label>Paid By:</label>
                  <input className='form-control'
                    type="text"
                    name="name"
                    value={paymentDetails.name}
                    onChange={handlePaymentChange}
                    required
                  />
                </div>

                <div className="form-group">


                  <label>Paid Amount:</label>
                  <input className='form-control'
                    type="number"
                    name="paidAmount"
                    value={paymentDetails.paidAmount}
                    onChange={handlePaymentChange}
                    required
                  />
                </div>
              </div>






              <div className='form-row'>
                <div className="form-group">

                  <label>Payment Method:</label>
                  <select className='form-control'
                    name="paymentMethod"
                    value={paymentDetails.paymentMethod}
                    onChange={handlePaymentChange}
                    required
                  >
                    <option value="cash">Cash</option>
                    <option value="cheque">Cheque</option>
                    <option value="qr">QR Payment</option>
                  </select>
                </div>
              </div>

              <button type="submit" className='badge bg-success'>Submit Payment</button>
            </form>
          </div>
        )}
      </div>
      <div className='pdf-info'>
        <h3>PDF Preview</h3>
        {/* PDF Preview Section */}
        {showPdfPreview && pdfPreview && (
          <div className="pdf-preview">

            <iframe src={pdfPreview} width="100%" height="500px" title="PDF Preview"></iframe>
          </div>
        )}


      </div>
    </div>
  );
};

export default Billing;
// UserList.js

import React from 'react';

const UserList = ({ users, onView }) => {
  return (
    <div className="user-list box">
      <div className="search">
        <h2 className='main-heading'>Users</h2>
        
      </div>
      <div className='table-container'>
        <table>
          <thead>
            <tr>
              <th className='sub-heading'>Name</th>
              <th className='sub-heading'>Role</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={index}>
                <td>{user.name}</td>
                <td>{user.role}</td>
                <td>
                  <button className='badge bg-success' onClick={() => onView(user)}>View</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserList;

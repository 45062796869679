import React from 'react';

const StudentPreview = ({ student, onEdit, onDelete }) => {
  // Check if student data is available
  if (!student) {
    return <p>No student data available</p>;
  }

  // Create a URL for the student's photo if available
  const photoUrl = student.photo
    ? `https://rosemary.bluebugsoft.com/rosemary_db/students_img/${student.photo}`
    : "https://via.placeholder.com/150";

  // Provide default values if properties are missing
  const firstName = student.firstName || 'N/A';
  const middleName = student.middleName || '';
  const lastName = student.lastName || 'N/A';
  const fatherName = student.fathersName || 'N/A'; // Use fathersName
  const motherName = student.mothersName || 'N/A'; // Use mothersName
  const phoneNumber = student.phoneNumber || 'N/A';
  const email = student.email || 'N/A';
  const className = student.className || 'N/A';
  const gender = student.gender || 'N/A';
  const dateOfBirth = student.dateOfBirth || 'N/A';

  return (
    <div className="student-preview box fix-height">
      <h2 className='main-heading'>Student Details Preview</h2>
      <div className="profile-item">
        <div className='profile'>
          <img src={photoUrl} alt="Student Profile" width={'100px'} />
        </div>
        <div className='profile-details'>
          <h3>{`${firstName} ${middleName} ${lastName}`.trim()}</h3>
          <p>Student ID: {student.studentId || 'N/A'}</p>
        </div>
      </div>
      <div className="preview-item">
        <h5 className='sub-heading'>Basic Details</h5>
        <p><strong>Class:</strong> {className}</p>
        <p><strong>Gender:</strong> {gender}</p>
        <p><strong>Date of Birth:</strong> {dateOfBirth}</p>
      </div>
      <div className="preview-item">
        <h5 className='sub-heading'>Parents Information</h5>
        <p><strong>Father's Name:</strong> {fatherName}</p>
        <p><strong>Mother's Name:</strong> {motherName}</p>
        <p><strong>Phone Number:</strong> {phoneNumber}</p>
        <p><strong>Email:</strong> {email}</p>
      </div>
      <div className="actions">
        <button className='badge bg-success' onClick={() => onEdit(student)}>Edit</button>
        <button className='badge bg-danger' style={{ marginLeft: '10px' }} onClick={() => onDelete(student.studentId)}>Delete</button>
      </div>
    </div>
  );
};

export default StudentPreview;

import React, { useState, useEffect } from 'react';

const ClassOverview = ({ selectedClass, onEditClass, onDeleteClass }) => {
  const [students, setStudents] = useState([]);

  useEffect(() => {
    if (selectedClass) {
      const fetchStudents = async () => {
        try {
          const response = await fetch(`https://rosemary.bluebugsoft.com/rosemary_db/getStudentsByClass.php?className=${encodeURIComponent(selectedClass.className)}`);
          const result = await response.json();
          setStudents(result.students);
        } catch (error) {
          console.error('Error fetching students:', error);
        }
      };

      fetchStudents();
    }
  }, [selectedClass]);

  if (!selectedClass) {
    return <div className="class-overview">Select a class to view details</div>;
  }

  return (
    <div className="class-overview">
      <h2 className='main-heading'>Class Overview</h2>
      <p className='sub-heading'>Class: {selectedClass.className}</p>
      <p className='sub-heading'>Total Students: {students.length}</p>
      <button className="badge bg-success " onClick={() => onEditClass(selectedClass)}>Edit Class</button>
      <button className="badge bg-danger " style={{ marginLeft: '15px' }} onClick={() => onDeleteClass(selectedClass)}>Delete Class</button>
      <h3 className='main-heading' style={{ marginTop: '30px' }}>Class Students</h3>
      <ul style={{listStyle:'number'}}>
        {students.map((student) => (
          <li key={student.id} style={{listStyle:'number', display:'flex',justifyContent:'space-between', width:'60%', }}>
            <span className='sub-heading' style={{textAlign:'left'}}>{student.firstName} {student.lastName}</span>
            <span className='sub-heading'style={{textAlign:'left'}}>{student.studentId}</span>
            <span className='sub-heading'style={{textAlign:'left'}}>{student.className}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ClassOverview;
import React, { useState } from 'react';

const roles = ['Admin', 'Principal', 'Accountant', 'Coordinator'];

const AddAdminUser = ({ onAdd }) => {
  const [newUser, setNewUser] = useState({
    username: '',
    firstName: '',
    middleName: '',
    lastName: '',
    role: roles[0],
    password: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://rosemary.bluebugsoft.com/rosemary_db/add_user.php', { // Adjust URL as needed
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newUser)
      });

      const result = await response.json();

      if (result.success) {
        alert('User added successfully');
        onAdd(newUser);
        setNewUser({
          username: '',
          firstName: '',
          middleName: '',
          lastName: '',
          role: roles[0],
          password: ''
        });
      } else {
        alert('Error adding user: ' + result.message);
      }
    } catch (error) {
      alert('Error: ' + error.message);
    }
  };

  return (
    <div className="add-admin-user box">
      <h2 className='main-heading'>Add Admin Users</h2>
      <form onSubmit={handleSubmit}>
        <div className='form-row'>
          
          <div className="form-group">
            <label>First Name</label>
            <input
              type="text"
              className='form-control'
              name="firstName"
              value={newUser.firstName}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Middle Name</label>
            <input
              type="text"
              className='form-control'
              name="middleName"
              value={newUser.middleName}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className='form-row'>
          <div className="form-group">
            <label>Last Name</label>
            <input
              type="text"
              className='form-control'
              name="lastName"
              value={newUser.lastName}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Role</label>
            <select
              name="role"
              className='form-control'
              value={newUser.role}
              onChange={handleChange}
            >
              {roles.map((role, index) => (
                <option key={index} value={role}>{role}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-group">
            <label>Username</label>
            <input
              type="text"
              className='form-control'
              name="username"
              value={newUser.username}
              onChange={handleChange}
            />
          </div>
        <div className='form-group'>
          <label>Password</label>
          <input
            type="password"
            className='form-control'
            name="password"
            value={newUser.password}
            onChange={handleChange}
          />
        </div>

        <button type="submit" className='badge bg-success' style={{ marginTop: '30px' }}>Submit</button>
      </form>
    </div>
  );
};

export default AddAdminUser;

import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';

const AddClassModal = ({ isOpen, onRequestClose, onAddClass, editClass }) => {
  const [className, setClassName] = useState('');

  useEffect(() => {
    if (editClass) {
      setClassName(editClass.className);
    }
  }, [editClass]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const classData = {
      className,
    };

    const url = editClass 
      ? 'https://rosemary.bluebugsoft.com/rosemary_db/editClass.php' // Update URL path as needed for editing
      : 'https://rosemary.bluebugsoft.com/rosemary_db/addClass.php'; // URL path for adding new class

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...classData, id: editClass?.id }) // Include ID for editing
      });

      const resultText = await response.text(); // Read response as text for debugging

      try {
        const result = JSON.parse(resultText); // Attempt to parse the response as JSON
        if (response.ok) {
          alert(result.message);
          onAddClass(classData);
          onRequestClose();
        } else {
          alert('Failed to save class: ' + result.message);
        }
      } catch (error) {
        alert('Invalid JSON response: ' + resultText); // Alert if the response is not valid JSON
        console.error('Response text:', resultText);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error saving class');
    }
  };

  return (
    <ReactModal isOpen={isOpen} onRequestClose={onRequestClose} ariaHideApp={false}>
      <div className='pop-up-edit'>
        <h2 style={{ color: 'white' }} className='main-heading'>{editClass ? 'Edit Class' : 'Add New Class'}</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group">
              <label className='sub-heading' style={{ color: 'white' }}>Class Name</label>
              <input type="text" className='form-control' value={className} onChange={(e) => setClassName(e.target.value)} required />
            </div>
          </div>

          <button className="badge bg-success" type="submit">{editClass ? 'Save Changes' : 'Add Class'}</button>
          <button className="badge bg-warning" style={{ marginLeft: '15px' }} type="button" onClick={onRequestClose}>Cancel</button>
        </form>
      </div>
    </ReactModal>
  );
};

export default AddClassModal;

import React, { useState } from 'react';
import EnrollmentForm from '../../components/EnrollmentForm';
import StudentPreview from '../../components/StudentPreview';

const Enrollment = () => {
  const [studentData, setStudentData] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: '',
    gender: '',
    className: '',
    studentId: '',
    fathersName: '',
    mothersName: '',
    address: '',
    phoneNumber: '',
    email: '',
    photo: ''
  });

  const handleStudentDataChange = (data) => {
    setStudentData(data);
  };

  return (
    <div className="enrollment-page">
      <EnrollmentForm onStudentDataChange={handleStudentDataChange} />
      <StudentPreview student={studentData} />
    </div>
  );
};

export default Enrollment;

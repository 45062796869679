import React, { useState, useEffect } from 'react';

const StudentList = ({ students, onSelectStudent, onStudentsFetched }) => {
  const [selectedClass, setSelectedClass] = useState('');
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [classes, setClasses] = useState([]);

  useEffect(() => {
    // Fetch classes when component mounts
    fetch('https://rosemary.bluebugsoft.com/rosemary_db/get_classes.php')
      .then(response => response.json())
      .then(data => setClasses(data))
      .catch(error => console.error('Error fetching classes:', error));
  }, []);

  useEffect(() => {
    if (selectedClass) {
      // Fetch students for the selected class
      fetch(`https://rosemary.bluebugsoft.com/rosemary_db/get_students.php?className=${selectedClass}`)
        .then(response => response.json())
        .then(data => {
          setFilteredStudents(data);
          onStudentsFetched(data);
        })
        .catch(error => console.error('Error fetching students:', error));
    } else {
      setFilteredStudents(students);
      onStudentsFetched(students);
    }
  }, [selectedClass, students]);

  return (
    <div className="student-list box fix-height">
      <div className="search">
        <h2 className="main-heading">Students</h2>

        <select className='form-control'
          style={{ marginLeft: '100px' }}
          value={selectedClass}
          onChange={(e) => setSelectedClass(e.target.value)}
        >
          <option value="">Select a class</option>
          {classes.map((classItem) => (
            <option key={classItem.id} value={classItem.class_name}>
              {classItem.class_name}
            </option>
          ))}
        </select>

      </div>
      <ul>
        {filteredStudents.length > 0 ? (
          filteredStudents.map((student) => (
            <li key={student.id}>
              <span>
                <img
                  src={student.photo ? `https://rosemary.bluebugsoft.com/rosemary_db/students_img/${student.photo}` : "https://via.placeholder.com/150"}
                  className="profile-pic"
                  alt="Student"
                />
              </span>
              <span>{student.firstName} {student.middleName} {student.lastName}</span>
              <span>{student.studentId}</span>
              <span>{student.className}</span>
              <button className='badge bg-success' onClick={() => onSelectStudent(student)}>View</button>
            </li>
          ))
        ) : (
          <li>No students found</li>
        )}
      </ul>
    </div>
  );
};

export default StudentList;